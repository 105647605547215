/* Include this stylesheet after Milligram or merge it with your main.css */

body.dark {
  background: #2c2f34;
  color: #f2f2f2;
}

.dark .navigation .title,
.dark .navigation-title {
  color: #f2f2f2;
}

.dark .navigation {
  background: #34373c;
  border-bottom: 0.1rem solid #1b1c1d;
}

.dark .header {
  background-color: #141518;
}

.dark pre {
  background: #141518;
}

.dark .prettyprint {
  color: #f2f2f2;
}

.dark .prettyprint.lang-md * {
  color: #f2f2f2 !important;
}

.dark code {
  background: #141518;
}

.dark .prettyprint .atv {
  color: rgba(73, 158, 223, 1);
}

.dark a {
  color: #f2f2f2;
}

.dark .add-job, .dark .job, .dark .job h1, .dark .job p {
  background: #34373c;
  color: #f2f2f2;
}

.dark input, .dark textarea, .dark select {
  color: #f2f2f2;
}

.dark option {
  background: #34373c;
}

.dark .user-box ul {
  background: #34373c;
}

.dark .user-box ul:before {
  border-color: transparent transparent #34373c #34373c;
}

.dark .user-box ul > li > label {
  color: #fff;
}

.dark .notification-box ul {
  background: #34373c;
}

.dark .notification-box ul:before {
  border-color: transparent transparent #34373c #34373c;
}

.dark .notification-box ul > li > a {
  color: #fff;
}

.dark .modal-content {
  background: #2c2f34;
  color: #f2f2f2;
}

.dark .multi-select__control {
  background: inherit;
}

.dark .multi-select__multi-value {
  background-color: #34373c;
  color: #f2f2f2;
}

.dark .multi-select__multi-value__label {
  color: #f2f2f2;
}

.dark .multi-select__menu {
  background: inherit;
}

.dark input:disabled, .dark textarea:disabled {
  background-color: #333;
}