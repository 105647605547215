body {
  margin: 0;
}

main {
  max-width: 85vw;
  margin: auto;
  padding: 2rem 1rem;
}

main>h1 {
  text-align: center;
}

textarea {
  height: auto;
}

.add-job {
  margin: 2rem auto;
  padding: 3rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.job {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
  margin: 2rem auto;
  background: #fff;
  border-radius: 10px;
}

.job h1 {
  color: #222;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 1.7rem;
}

.job p {
  padding-right: 1rem;
  color: #444;
}

.job button {
  border: 1px solid #d34b4b;
  background: #d34b4b;
  color: #fff;
}

.Article button:hover {
  background: #e06a6a;
}

.navigation .button {
  margin: 6px;
}

.navigation-list {
  margin-right: 15px;
}

.user-box {
  margin: 2px 15px;
  padding: 1px;
  border-radius: 6px;
  position: relative;
  z-index: 10;
}

.user-box label {
  font-weight: normal;
  line-height: 2.6;
  position: relative;
  cursor: pointer;
}

.user-box ul {
  display: none;
  position: absolute;
  border-radius: 7px;
  background: #fff;
  box-shadow: 0px 0px 8px rgba(214, 214, 214, 0.78);
  list-style: none;
  padding: 4px 20px 0px 20px;
  width: 200px;
  top: 70px;
  right: 10px;
}

.user-box ul:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -0.5em;
  right: 0px;
  box-sizing: border-box;
  border: 7px solid black;
  border-color: transparent transparent #ffffff #ffffff;
  transform-origin: 0 0;
  transform: rotate(135deg);
  box-shadow: -3px 3px 3px -3px rgba(214, 214, 214, 0.78);
}

.user-box ul.open {
  display: block;
}

.user-box ul>li {
  padding: 0px;
  margin: 0px;
  border-bottom: 1px solid rgba(215, 215, 215, 0.17);
}

.user-box ul>li:last-child {
  border-bottom: 0px;
}

.user-box ul>li>label {
  font-size: 13px;
  padding: 10px;
  margin: 0;
  color: #002f60;
  transition: all 0.2s ease-out;
}

.user-box ul>li:before {
  content: "";
  width: 0px;
  height: 40px;
  position: absolute;
  background: #002f60;
  margin-top: 4px;
  border-radius: 0 1px 1px 0;
  left: 0px;
  transition: all 0.2s ease;
}

.user-box ul>li:hover:before {
  width: 5px;
  border-radius: 30px;
}

.user-box ul>li label:hover {
  margin-left: 5px;
}

.user-box button {
  margin: 3px;
}

.notification-box {
  margin: 2px 15px;
  padding: 1px;
  border-radius: 6px;
  position: relative;
  z-index: 2;
}

.notification-box label {
  font-weight: normal;
  line-height: 2.6;
  position: relative;
  cursor: pointer;
}

.notification-box ul {
  display: none;
  position: fixed;
  border-radius: 7px;
  background: #fff;
  box-shadow: 0px 0px 8px rgba(214, 214, 214, 0.78);
  list-style: none;
  padding: 4px 20px 0px 20px;
  top: 70px;
  right: 120px;
}

.notification-box ul:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -0.5em;
  right: 0px;
  box-sizing: border-box;
  border: 7px solid black;
  border-color: transparent transparent #ffffff #ffffff;
  transform-origin: 0 0;
  transform: rotate(135deg);
  box-shadow: -3px 3px 3px -3px rgba(214, 214, 214, 0.78);
}

.notification-box ul.open {
  display: block;
}

.notification-box ul>li {
  padding: 0px;
  margin: 0px;
  border-bottom: 1px solid rgba(215, 215, 215, 0.17);
}

.notification-box ul>li:last-child {
  border-bottom: 0px;
}

.notification-box ul>li>a {
  padding: 10px;
  margin: 0;
  color: #002f60;
  transition: all 0.2s ease-out;
}

.theme-toggle {
  float: right;
  margin: 0px 0px 0px 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #222;
}

input:focus+.slider {
  box-shadow: 0 0 1px #222;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.modal {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: "scroll";
  z-index: 999;
  padding: 40px 20px 20px;
}

.modal-content {
  position: relative;
  z-index: 999;
  width: 70%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-close-btn {
  position: absolute;
  top: 15px;
  right: 25px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.4em;
}

.modal-form {
  padding: 55px 25px 25px 25px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-form-button-wrapper {
  width: 100%;
  max-width: 500px;
  margin: auto;
}

.modal-form-button-wrapper button {
  float: right;
  margin: 8px;
}

.modal-form-fields-scroll-wrapper {
  width: 100%;
  max-height: calc(100% - 30px);
  overflow-y: auto;
  align-items: center;
  justify-content: center;
}

.modal-form-fields {
  width: 100%;
  max-width: 500px;
  margin: auto;
}

.react-table td {
  padding: 1.4rem;
}

.react-table td:last-child {
  padding: 0;
  width: 100px;
  min-width: 100px;
}

.react-table .awaiting {
  background-color: #0074D9;
  color: #fff;
}

.react-table .rejected {
  background-color: #FF851B;
  color: #fff;
}

.react-table .expired {
  background-color: #ff1b39e6;
  color: #fff;
}

.button-small {
  font-size: .8rem;
  height: 2.8rem;
  line-height: 2.8rem;
  padding: 0 1rem;
  margin: 0 4px;
  display: inline;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.edit-form {
  width: 100%;
  max-width: 800px;
  max-height: calc(100% - 40px);
  padding: 20px;
  overflow-y: scroll;
}

.loader,
.loader:after,
.loader:before {
  z-index: 9999;
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: #606c76;
  font-size: 10px;
  margin: 80px auto;
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 14px);
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -.16s;
  animation-delay: -.16s;
}

.loader:after,
.loader:before {
  content: '';
  position: absolute;
  top: 0
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -.32s;
  animation-delay: -.32s;
}

.loader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {

  0%,
  100%,
  80% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {

  0%,
  100%,
  80% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.rotate {
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  display: inline-block;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(1turn);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

.multi-select__menu {
  position: initial !important;
}

.multi-select__option--is-focused {
  background-color: #002f60 !important;
  color: #fff !important;
}

.multi-select__multi-value__remove,
.multi-select__indicator {
  cursor: pointer;
}

.multi-select__multi-value__remove:hover {
  background-color: #FF4136 !important;
  color: #fff !important;
}

.error-message {
  color: #FF4136;
  font-weight: bold;
}

input.error,
textarea.error,
select.error {
  border-color: #FF4136;
}

.badge {
  background-color: #002f60;
  color: #fff;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 5px;
  font-weight: bold;
  font-size: 1.8rem;
}

.filters {
  float: right;
}

.filters input {
  padding: 5px;
  margin: 0;
}

.filters label {
  display: inline;
  padding: 0 25px 0 5px;
  margin: 0;
}

input:disabled,
textarea:disabled {
  background-color: #eee;
}

.prefixContainer {
  display: flex;
}

.prefixInput {
  display: flex;
  padding: 4px;
  margin-right: 2px;
  width: initial !important;
}

.breakRows>td {
  word-break: break-word;
}

.uploadImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.uploadImageButton {
  display: flex;
  margin-bottom: 0;
  align-self: center;
}

.uploadImageSubmit {
  margin-bottom: 0;
}

.manageListEditContainer {
  padding-left: 35px;
}

.accordion {
  margin: 1rem auto;
}
.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: #002f60;
  font-weight: 500;
  color: #f7f7f7;
}
.accordion-title:hover {
  background-color: #f4f5f6;
  color: #002f60;
}
.accordion-title,
.accordion-content {
  padding: 1rem;
}
.accordion-item {
  margin-bottom: 10px;
  border: 1px solid #002f60;
  border-radius: 3px;
}

.wfm-broken-cells {
  word-break: break-word;
}

@media screen and (max-width: 900px) {
  body {
    font-size: 18px;
  }
  .accordion {
    width: 95%;
  }
  th {
    font-size: 12px;
  }
  td {
    font-size: 12px;
    padding: 1rem;
  }
}

.tableHeader {
  color: #002f60;
  margin: 10px 0 0 0;
}

.italics {
  font-style: italic;
}

.toast-container {
  position: fixed;
  top: 10px;
  right: 10px;
  max-width: 300px; /* Adjust the maximum width as needed */
  z-index: 1000;
  display: flex;
  flex-direction: column; /* Display new toasts at the top */
  align-items: flex-end; /* Align toasts to the right */
}

.toast {
  display: flex;
  width: 100%;
  background-color: #fff; /* Background color for the toast */
  border-radius: 4px;
  margin: 5px 0; /* Adjust the margin between toasts */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, opacity 0.3s;
  transform: translateY(100%);
}

.toast-indicator {
  width: 10px; /* Width of the indicator */
  background-color: #4CAF50; /* Default color for success */
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  z-index: 1; /* Ensure the indicator appears over the content */
}

.toast-content {
  margin: 4px;
  flex: 1;
  padding: 8px;
}

.toast.show {
  transform: translateY(0);
  opacity: 1;
}

.toast.hide {
  transform: translateY(100%);
  opacity: 0;
}

.success .toast-indicator {
  background-color: #4CAF50; /* Success color for the indicator */
}

.error .toast-indicator {
  background-color: #f44336; /* Error color for the indicator */
}

.information .toast-indicator {
  background-color: #2196F3; /* Information color for the indicator */
}