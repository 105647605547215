@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?i52xjx');
  src:  url('fonts/icomoon.eot?i52xjx#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?i52xjx') format('truetype'),
    url('fonts/icomoon.woff?i52xjx') format('woff'),
    url('fonts/icomoon.svg?i52xjx#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bell:before {
  content: "\e906";
  font-size: small;
}
.icon-moon:before {
  content: "\e900";
  font-size: small;
}
.icon-down:before {
  content: "\e904";
  font-size: small;
}
.icon-up:before {
  content: "\ea3a";
  font-size: small;
}
.icon-pencil:before {
  content: "\e905";
  font-size: small;
}
.icon-spinner:before {
  content: "\e97a";
  font-size: small;
}
.icon-sun:before {
  content: "\e9d4";
  font-size: small;
}
.icon-cancel:before {
  content: "\ea0f";
  font-size: small;
}
.icon-info:before {
  content: "\e901";
  font-size: small;
}
