.bin-icon {
  transform: translate(10px, 10px);
  margin-top: 0vh;
}

.add-tenant-container {
  display: flex;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.add-message-container {
  display: flex;
}

.add-message-text {
  margin-top: 2px;
}

.add-message-icon {
  margin-left: 1vw;
}

.table-container {
  margin: 20px auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.ticker-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  table-layout: fixed;
}

.ticker-table thead {
  background-color: #007bff;
  color: #fff;
}

.ticker-table th, .ticker-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  width: 48%;
}

.ticker-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.ticker-table tr:hover {
  background-color: #e6f7ff;
  cursor: pointer;
}

.ticker-table th {
  font-weight: bold;
}

.ticker-table td {
  font-size: 0.9em;
  color: #333;
}
